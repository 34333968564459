import { useEffect } from "react";
import imagen from "../../assets/img1.jpeg";
import "./Login.css";   
import { FormForgetPassword } from "../../containers/FormForgetPassword/FormForgetPassword";
function ResetPassword() {
    useEffect(() => {
        const height = document.getElementsByClassName("column-rigth")[0];
        const element = document.getElementsByClassName("login-img")[0];
    
        new Promise((resolve) =>
          setTimeout(() => {
            element.style.height = height.clientHeight + "px";
            
            resolve(true);
          }, 10)
        );
    
        function handleResize() {
          let height = document.getElementsByClassName("column-rigth")[0];
          if(height){
            //console.log("resized to: ", height.clientHeight, "x", height.clientWidth);
          }
        }
    
        window.addEventListener("resize", handleResize);
      });
  return (
    <div className="ResetPassword container-animated">
      <div className="login-container">
        <div className="container">
          <div className="column-left box">
            <div className="login-img">
              <div className="img-container">
                <img src={imagen} />
              </div>
            </div>
          </div>
          <div className="column-rigth box">
            <FormForgetPassword />
            {/* <div className="copy-rigth">
              <div className="footer-left">
                <div className="float-content">
                  Copyright © 2022 Lincep V 1.0
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword