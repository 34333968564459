export const newPasswordForm = {
  
  password: {
    name: "password",
    label: "Contraseña",
    validators: {
      required: {
        value: true,
        message: "Este campo es requerido",
      },
      pattern: {
        value:
          /^(?=.*[A-ZÀ-ÿ\u00f1\u00d1])(?=.*\d)(?=.*[@$!%*?&\+\-])[A-Za-zÀ-ÿ\u00f1\u00d1\d@$!%*?&\+\-]+$/,
        message:
          'La contraseña debe contener al menos una mayúscula, un número y un carácter especial "@$!%*?&+-" y un mínimo de 5 carecteres',
      },
      minLength:{
        value:5,
        message: 'La contraseña debe contener al menos una mayúscula, un número y un carácter especial "@$!%*?&+-" y un mínimo de 5 carecteres',
      }
    },
    placeholder: "contraseña",
  },
  password_confirmation: {
    name: "password_confirmation",
    label: "Verificar contraseña",
    validators: {
      required: {
        value: true,
        message: "Este campo es requerido",
      },
    },
    placeholder: "verificar contraseña",
  },
  
};
