import { useNavigate } from "react-router-dom";
import BreadcrumbsComponent from "../../components/Breadcrumbs/BreadcrumbsComponent";
import CardResumen from "../../components/CardProject/CardProject";
import CoverCategoria from "../../components/CoverCategoryProject/CoverCategoryProject";
import "./PageInvest.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useEffect, useState } from "react";
import { IsUnauthorized, httpService } from "../../helpers/ApiService";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1350 },
    items: 4,
    slidesToSlide: 3, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1350, min: 720 },
    items: 3,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 720, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

export default function PageInvest() {
  const [showCarousel, setShowCarousel] = useState("none");
  useEffect(() => {
    setTimeout(() => {
      setShowCarousel("grid");
    }, 10);
  }, []);

  /**************Para pruebas***************** */
  const [activos, seActivos] = useState([]);
  const [loadActivos, setLoadActivos] = useState(false);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [next, setNext] = useState(0);
  const [previous, setPrevious] = useState(0);
  const [page, setPage] = useState(1);
  const [countResult, setCountResult] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [tipoProyecto, setTipoProyecto] = useState(0);
  const [tipoProyectos, setTipoProyectos] = useState([]);
  useEffect(() => {
    getTipoProyecto();
  }, [page]);
  useEffect(() => {
    let _offset = (page - 1) * limit;
    setOffset(_offset);
    getMisActivos(limit, _offset);
  }, [tipoProyecto]);

  const getMisActivos = (_limit = limit, _offset = offset) => {
    const url = `/asset/list/assets/?limit=${_limit}&offset=${_offset}&tipo=${tipoProyecto}`;

    return httpService(`${url}`)
      .get()
      .then((res) => {
        if (res instanceof Object) {
          if (res.results && res.count) {
            seActivos(res.results);
            setLoadActivos(true);
            setCountResult(res.results.length);
            if (res.next) {
              let paramsUrl = res.next.split("?");
              if (paramsUrl.length > 1) {
                const urlParams = new URLSearchParams(paramsUrl[1]);
                const _limit = urlParams.get("limit");
                const _offset = urlParams.get("offset");
                setLimit(_limit);
                setNext(_offset);
                let _pageCount = Math.ceil(res.count / _limit);
                setPageCount(_pageCount);
              }
            }
            if (res.previous) {
              let paramsUrl = res.previous.split("?");
              if (paramsUrl.length > 1) {
                const urlParams = new URLSearchParams(paramsUrl[1]);
                console.log("urlParams:", urlParams);
                const param1 = urlParams.get("limit");
                const param2 = urlParams.get("offset");
                console.log("limit:", param1);
                console.log("offset:", param2);
              }
            }
          } else {
            seActivos([]);
            setPageCount(0);
          }
        }
        return res;
      })
      .catch((err) => {
        console.error("XXXX Response:", err);
        console.error("XXXX Response status:", err.status);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        } else {
        }
        return;
      });
  };
  const getTipoProyecto = () => {
    const url = `/asset/list/tipoproyecto/`;
    return httpService(`${url}`)
      .get()
      .then((res) => {
        console.log("tipoProyecto res:", res);
        if (res instanceof Array) {
          let defaultTP = [
            {
              color: "#2cc7e2",
              id: 0,
              nombre: "Todos",
            },
          ];
          if (res.length == 0) {
            setTipoProyectos(defaultTP);
          } else {
            let data = [...defaultTP, ...res];
            setTipoProyectos(data);
          }
        } else {
          setTipoProyectos([]);
        }
      })
      .catch((err) => {
        console.error("XXXX Response:", err);
        console.error("XXXX Response status:", err.status);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        } else {
        }
        return;
      });
  };
  /**************Para pruebas***************** */

  const navigate = useNavigate();
  const handlerClic = (id) => {
    console.log("Redirect:", id);
    navigate("/invertir/proyecto/" + id);
  };
  const tipoProyectoHandle = (tp) => {
    console.log("TIPO PROYECTO:", tp);
    setTipoProyecto(tp);
  };
  return (
    <div className="page-invest container-animated">
      <div className="row">
        <div className="col-xl-12 col-lg-12">
          <BreadcrumbsComponent />
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="table-title blue">Categorías</div>
        </div>

        {tipoProyectos.length > 1 && (
          <div className="carousel-box">
            <div
              className="carousel-container container-animated"
              style={{ display: showCarousel }}
            >
              <Carousel
                swipeable={true}
                draggable={true}
                showDots={false}
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={5000}
                keyBoardControl={true}
                customTransition="all .5"
                transitionDuration={500}
                containerClass="carousel-container"
                /* removeArrowOnDeviceType={["tablet", "mobile"]} */
                removeArrowOnDeviceType={["mobile"]}
                deviceType={"desktop"}
                dotListClass="custom-dot-list-style"
                itemClass="carousel-item-padding-40-px"
              >
                {tipoProyectos.map((data) => {
                  return (
                    <div key={data.id}>
                      <div className="carousel-item-img">
                        <CoverCategoria
                          tipo={data.nombre}
                          src="https://static.overlay-tech.com/assets/5206383c-767b-48b6-9789-debab4e005e6.png"
                          handlerClic={() => tipoProyectoHandle(data.id)}
                        />
                      </div>
                    </div>
                  );
                })}
              </Carousel>
            </div>
          </div>
        )}
        {/* <div class="container-fluid">
      <div className="col-md-3">
          <CoverCategoria />
        </div>
        <div className="col-md-3">
          <CoverCategoria />
        </div>
      </div> */}
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="table-title blue">
            {tipoProyecto == 0 ? "Proyectos " : ""}
            {tipoProyectos.length > 0 && tipoProyectos[tipoProyecto].nombre}
          </div>
        </div>

        {activos.map((data) => {
          return (
            <div
              key={data.id}
              className="col-xl-3 col-lg-6 col-custom"
              onClick={() => handlerClic(data.id)}
            >
              <CardResumen data={data} />
            </div>
          );
        })}
        {activos.length === 0 && loadActivos && (
          <div className="w-100 mt-20">
            <div className="col-xl-12 col-lg-12 col-custom">No hay proyectos para esta categoría</div>
          </div>
        )}

        {/* <div className="col-xl-3 col-lg-6 col-custom" onClick={ ()=> handlerClic(2)}>
          <CardResumen />
        </div>
        <div className="col-xl-3 col-lg-6 col-custom" onClick={ ()=> handlerClic(3)}>
          <CardResumen />
        </div>
        <div className="col-xl-3 col-lg-6 col-custom" onClick={ ()=> handlerClic(4)}>
          <CardResumen />
        </div>
        <div className="col-xl-3 col-lg-6 col-custom" onClick={ ()=> handlerClic(5)}>
          <CardResumen />
        </div> */}
      </div>
    </div>
  );
}
