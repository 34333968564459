import { IconBoxMapW } from "../Icons/Icons";
import "./Marker.css";
const onMarkerClick = (handleClick, ev) => {
    ev.stopPropagation();
  if (handleClick) handleClick(ev);
};
export const Marker = ({ text = "", handleClick = null }) => {
  return (
    <div
      className="marker-container"
      onClick={($ev) => onMarkerClick(handleClick, $ev)}
    >
      <label className="textStyle">{text}</label>
      <div className="marker-box" style={handleClick?{cursor:"pointer"}:{cursor: "grab"}}>
        <IconBoxMapW
          fillOpacity="0.0"
          colorIcon={"#1b4ec1"}
          height={"40px"}
          width={"40px"}
        />
      </div>
    </div>
  );
};
