import React from "react";
import styles from "./CoverCategoryProject.module.css";
import './CoverCategoryProject.css';
const CoverCategoria = (props) => {
  return (
    <div className={styles.coverCategoria} style={{"cursor":"pointer"}} onClick={props.handlerClic}>
      <div className={styles.bg}>
        <img
          alt=""
          className="image-responsive"
          src={props.src}
        />
        <div className={styles.overlay} />
      </div>
      <p className={styles.text}>{props.tipo}</p>
    </div>
  );
};

export default CoverCategoria;