import React, { useEffect, useState } from "react";
/* import Logo from "../../assets/Logo/Logo"; */
import Dropdown from "react-bootstrap/Dropdown";

import "./Welcome.css";
import imagen from "../../assets/img_main.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./HeaderWelcome.module.css";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import Logo from "../../assets/Logo/Logo";
import phone from "../../assets/img_mockup_phone.png";
import capture from "../../assets/CAPTURE.png";

import captureApp from "../../assets/img_mockup_pantalla.png";
import Boton from "../../components/Boton";
import { useNavigate } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { CardBlog } from "../../components/CardBlog/CardBlog";
import CoverCategoria from "../../components/CoverCategoryProject/CoverCategoryProject";
import PageAcademy from "../PageAcademy/PageAcademy";

import { useParams } from "react-router-dom";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1350 },
    items: 2,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1150, min: 720 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 720, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};
function Welcome() {
  const { page } = useParams();
  const navigate = useNavigate();
  const [showCarousel, setShowCarousel] = useState("none");
  useEffect(() => {
    setTimeout(() => {
      setShowCarousel("grid");
    }, 100);
  }, []);
  useEffect(() => {
    //const height = document.getElementsByClassName("column-rigth")[0];
    const height = 10;
    const element = document.getElementsByClassName("welcome-img")[0];

    new Promise((resolve) =>
      setTimeout(() => {
        element.style.height = height.clientHeight + "px";
        //let imgW = element.getElementsByTagName('img')[0].style.width = height.clientWidth + "px"
        //let imgW = element.getElementsByTagName('img')[0].style.width = (height.clientWidth - 45) + "px"
        //let imgH = element.getElementsByTagName('img')[0].style.height = height.clientHeight + "px"
        resolve(true);
      }, 10)
    );

    function handleResize() {
      let height = document.getElementsByClassName("column-rigth")[0];
      if (height) {
        //console.log("resized to: ", height.clientHeight, "x", height.clientWidth);
      }

      /* let element = document.getElementsByClassName('login-img')[0];
      element.style.minHeight = height.clientHeight + "px"; */

      //let imgW = element.getElementsByTagName('img')[0].style.width = (height.clientWidth - 45) + "px"
      //let imgH = element.getElementsByTagName('img')[0].style.height = height.clientHeight + "px"
    }

    window.addEventListener("resize", handleResize);
  });
  const toRegister = () => {
    navigate("/register");
  };
  const toLogin = () => {
    navigate("/login");
  };
  const toAbout = () => {
    console.log("toAbout");
    //navigate("/login");
  };
  const toFaqs = () => {
    console.log("toFaqs");
    //navigate("/login");
  };
  const toContactUs = () => {
    console.log("toContactUs");
    //navigate("/login");
  };
  const content2 = () => {
    return (
      <div style={{ fontSize: "13px", textAlign: "start", color: "#4d4d4d" }}>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry.
      </div>
    );
  };
  const content3 = () => {
    return (
      <div
        style={{ marginTop: "10px", marginBottom: "20px", cursor: "pointer" }}
      >
        <div
          style={{
            fontSize: "12px",
            fontFamily: "Poppins-Bold",
            padding: "3px 10px",
            background: "#b9de2c",
            borderRadius: "5px",
          }}
        >
          Ver más
        </div>
      </div>
    );
  };

  return (
    <div className="Welcome container-animated">
      <div
        style={{
          position: "fixed",
          width: "100%",
          backgroundColor: "rgba(255,255,255,.8)",
          backdropFilter: "blur(10px)",
          zIndex: 999,
        }}
        className={styles.headerDashboard}
      >
        <div>
          <div className="menu-text">
            <Logo width="75%" height="80%" />
          </div>
        </div>
        <div className="container-buttons-box container-animated">
          {
            <div className="box-buttons-container">
              <div className="box-buttons">
                <div onClick={toAbout} className="link-about">
                  Sobre Devise
                </div>
              </div>
            </div>
          }
          {
            <div className="box-buttons-container">
              <div className="box-buttons">
                <div onClick={toFaqs} className="link">
                  Preguntas frecuentes
                </div>
              </div>
            </div>
          }
          {
            <div className="box-buttons-container">
              <div className="box-buttons">
                <div onClick={toContactUs} className="link">
                  Contáctanos
                </div>
              </div>
            </div>
          }
          {
            <div className="box-buttons-container">
              <div className="box-buttons">
                <div className="btn-cancel">
                  <Boton
                    className={"btn _btn-primary font-14"}
                    handlerClick={toRegister}
                    style={{
                      borderRadius: "8px",
                      width: "115px",
                      background: "white",
                      border: "1px solid #112e6d",
                      color: "#112e6d",
                    }}
                    label={"Registrarse"}
                  />
                </div>
              </div>
            </div>
          }
          {
            <div className="box-buttons-container">
              <div className="box-buttons">
                <div className="btn-logout">
                  <Boton
                    className={"btn _btn-primary font-14"}
                    style={{ borderRadius: "8px", width: "145px" }}
                    label={"Entrar a Devise"}
                    handlerClick={toLogin}
                  />
                </div>
              </div>
            </div>
          }
        </div>
        <div className="container-buttons-dropdown container-animated">
          <Dropdown>
            <Dropdown.Toggle variant="transparent" id="dropdown-basic">
              <FontAwesomeIcon
                style={{
                  width: "25px",
                  height: "25px",
                  color: "#787878",
                  cursor: "pointer",
                }}
                /* onClick={props.handlerClick.hideMenu} */
                icon={faBars}
              />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="#/about">
                <div className="box-buttons-container">
                  <div className="box-buttons">
                    <div onClick={toAbout} className="link-about">
                      Sobre Devise
                    </div>
                  </div>
                </div>
              </Dropdown.Item>
              <Dropdown.Item href="#/faqs" active>
                <div className="box-buttons-container">
                  <div className="box-buttons">
                    <div onClick={toFaqs} className="link">
                      Preguntas frecuentes
                    </div>
                  </div>
                </div>
              </Dropdown.Item>
              <Dropdown.Item href="#/contact-us">
                <div className="box-buttons-container">
                  <div className="box-buttons">
                    <div onClick={toContactUs} className="link">
                      Contáctanos
                    </div>
                  </div>
                </div>
              </Dropdown.Item>
              <Dropdown.Item>
                <div className="box-buttons-container">
                  <div className="box-buttons">
                    <div className="btn-cancel">
                      <Boton
                        className={"btn _btn-primary font-14"}
                        handlerClick={toRegister}
                        style={{
                          borderRadius: "8px",
                          width: "115px",
                          background: "white",
                          border: "1px solid #112e6d",
                          color: "#112e6d",
                        }}
                        label={"Registrarse"}
                      />
                    </div>
                  </div>
                </div>
              </Dropdown.Item>
              <Dropdown.Item>
                <div className="box-buttons-container">
                  <div className="box-buttons">
                    <div className="btn-logout">
                      <Boton
                        className={"btn _btn-primary font-14"}
                        style={{ borderRadius: "8px", width: "145px" }}
                        label={"Entrar a Devise"}
                        handlerClick={toLogin}
                      />
                    </div>
                  </div>
                </div>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    {/* <!----CONTAINER-------> */}
    {!page && <>
      <div className="welcome-container">
        <div className="container">
          <div className="column-left box">
            <div className="welcome-img">
              <div className="img-container">
                <img src={imagen} />
              </div>
              <div className="text-banner">
                <div className="text-header big-h1 primary">
                  Reinventando el <span className="secondary-lightblue">valor Inmobiliario</span>
                </div>
                <div className="text-small primary">
                  Invertir en proyectosinmobiliarios nunca antes ha sido tan
                  fácil, rápido y seguro
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{ paddingTop: "0px", paddingBottom: "20px", height: "100%" }}
        className="welcome-container"
      >
        <div className="row-section">
          <div style={{ overflow: "hidden" }} className="col-md-6 section">
            <img className="img-border translate-left" src={captureApp} />
          </div>
          <div className="col-md-6 section">
            <div className="section-box">
              <h1 className="big-h1 primary align-self-center">
                Invertir en inmuebles{" "}
                <span className="secondary">a un solo click</span>
              </h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Pellentesque sit amet justo leo. Aenean ut dui luctus, luctus
                justo nec, condimentum sapien. Nunc quis felis eu nulla gravida
                vulputate. Vestibulum sodales, nisl quis dignissim vestibulum,
                nisi lorem pellentesque est, ac scelerisque ex mauris ut leo.
                Nam iaculis interdum porta. Proin pulvinar dolor a augue
                vulputate vehicula. Suspendisse quis pharetra justo.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{ paddingTop: "60px", paddingBottom: "20px", height: "100%" }}
        className="welcome-container section-purple"
      >
        <div className="row-section">
          <div className="col-md-6 section">
            <div className="section-box section-box-70">
              <h1 className="big-h1 secondary-lighter align-self-center">
                Ten el control desde{" "}
                <span className="secondary">tu telefono</span>
              </h1>
              <p style={{ marginTop: "40px" }} className="secondary-lighter">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Pellentesque sit amet justo leo. Aenean ut dui luctus, luctus
                justo nec, condimentum sapien. Nunc quis felis eu nulla gravida
                vulputate. Vestibulum sodales, nisl quis dignissim vestibulum,
                nisi lorem pellentesque est, ac scelerisque ex mauris ut leo.
                Nam iaculis interdum porta. Proin pulvinar dolor a augue
                vulputate vehicula. Suspendisse quis pharetra justo.{" "}
              </p>
            </div>
          </div>

          <div className="col-md-6 section">
            <img className="img-border" src={phone} />
          </div>
        </div>
      </div>

      <div
        style={{ paddingTop: "60px", paddingBottom: "20px", height: "100%" }}
        className="welcome-container section"
      >
        <div className="row-section">
          <div className="col-md-6 section">
            <img
              style={{ width: "280px", margin: "auto" }}
              className="img-border"
              src={capture}
            />
          </div>

          <div className="col-md-6 section">
            <div className="section-box section-box-70">
              <h1 className="big-h1 secondary align-self-center">
                Gestiona <span className="primary">tu </span>
                dinero <span className="primary">y tus </span>
                inversiones
              </h1>
              <p style={{ marginTop: "40px" }} >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Pellentesque sit amet justo leo. Aenean ut dui luctus, luctus
                justo nec, condimentum sapien. Nunc quis felis eu nulla gravida
                vulputate. Vestibulum sodales, nisl quis dignissim vestibulum,
                nisi lorem pellentesque est, ac scelerisque ex mauris ut leo.
                Nam iaculis interdum porta. Proin pulvinar dolor a augue
                vulputate vehicula. Suspendisse quis pharetra justo.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          paddingTop: "60px",
          paddingBottom: "20px",
          height: "100%",
          "backgroundImage": "url(40, 41, 79,.9)",
        }}
        className="welcome-container section welcome-container-info-image"
      >
        <div className="row-section">
          <div
            className="col-md-4 section carousel-text"
            style={{ height: "auto" }}
          >
            <div className="section-box section-box-70">
              <h1 className="big-h1 secondary align-self-center">
                Mantente informado
              </h1>
            </div>
          </div>

          <div className="col-md-8 section" style={{ height: "auto" }}>
            <div className="row-section">
              <div className="carousel-box">
                <div
                  className="carousel-container container-animated"
                  style={{ display: showCarousel }}
                >
                  <Carousel
                    swipeable={true}
                    draggable={true}
                    showDots={false}
                    responsive={responsive}
                    ssr={true} // means to render carousel on server-side.
                    infinite={true}
                    autoPlay={false}
                    autoPlaySpeed={10000}
                    keyBoardControl={true}
                    customTransition="all .5"
                    transitionDuration={500}
                    containerClass="carousel-container"
                    /* removeArrowOnDeviceType={["tablet", "mobile"]} */
                    removeArrowOnDeviceType={["mobile"]}
                    deviceType={"desktop"}
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-padding-40-px"
                  >
                    <div className="carousel-item-img">
                      <CardBlog
                        bg_color="var(--secondarycolorpalette-lightblue-ligtheri)"
                        content1="Liberta Financiera, claves para 2019"
                        content2={content2()}
                        content3={content3()}
                      />
                    </div>
                    <div className="carousel-item-img">
                      <CardBlog
                        bg_color="#bbacf2"
                        content1="Liberta Financiera, claves para 2020"
                        content2={content2()}
                        content3={content3()}
                      />
                    </div>
                    <div className="carousel-item-img">
                      <CardBlog
                        bg_color="#bbacf2"
                        content1="Liberta Financiera, claves para 2021"
                        content2={content2()}
                        content3={content3()}
                      />
                    </div>
                    <div className="carousel-item-img">
                      <CardBlog
                        bg_color="var(--secondarycolorpalette-lightorange-lighter)"
                        content1="Liberta Financiera, claves para 2022"
                        content2={content2()}
                        content3={content3()}
                      />
                    </div>
                    <div className="carousel-item-img">
                      <CardBlog
                        bg_color="var(--secondarycolorpalette-hueso-light)"
                        content1="Liberta Financiera, claves para 2023"
                        content2={content2()}
                        content3={content3()}
                      />
                    </div>
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>}
      
      
      
      
      {/* <div
        
        className="welcome-container section"
      >
        <div className="container">
          
        <PageAcademy hideBreadcrumbs={true} />
        </div>
        
      </div> */}
    {/* <!----CONTAINER-------> */}
       
      <div
        style={{
          position: "flex",
          width: "100%",
          backgroundColor: "rgba(255,255,255,.8)",
          backdropFilter: "blur(10px)",
          height: "100px",
          maxWidth: "1500px",
          margin: "10px auto",
        }}
      >
        <div>
          <div className="menu-text" style={{ marginTop: "-15px" }}>
            <Logo width="75%" height="80%" />
          </div>
        </div>
        <div
          className="container-buttons-box container-animated"
          style={{ paddingTop: "10px" }}
        >
          {
            <div className="box-buttons-container">
              <div className="box-buttons">
                <div onClick={toAbout} className="link-about">
                  Sobre Devise
                </div>
              </div>
            </div>
          }
          {
            <div className="box-buttons-container">
              <div className="box-buttons">
                <div onClick={toFaqs} className="link">
                  Preguntas frecuentes
                </div>
              </div>
            </div>
          }
          {
            <div className="box-buttons-container">
              <div className="box-buttons">
                <div onClick={toContactUs} className="link">
                  Contáctanos
                </div>
              </div>
            </div>
          }
          {
            <div className="box-buttons-container">
              <div className="box-buttons">
                <div className="btn-cancel">
                  <Boton
                    className={"btn _btn-primary font-14"}
                    handlerClick={toRegister}
                    style={{
                      borderRadius: "8px",
                      width: "115px",
                      background: "white",
                      border: "1px solid #112e6d",
                      color: "#112e6d",
                    }}
                    label={"Registrarse"}
                  />
                </div>
              </div>
            </div>
          }
          {
            <div className="box-buttons-container">
              <div className="box-buttons">
                <div className="btn-logout">
                  <Boton
                    className={"btn _btn-primary font-14"}
                    style={{ borderRadius: "8px", width: "145px" }}
                    label={"Entrar a Devise"}
                    handlerClick={toLogin}
                  />
                </div>
              </div>
            </div>
          }
        </div>
        <div className="container-buttons-dropdown container-animated footer">
          <Dropdown>
            <Dropdown.Toggle variant="transparent" id="dropdown-basic">
              <FontAwesomeIcon
                style={{
                  width: "25px",
                  height: "25px",
                  color: "#787878",
                  cursor: "pointer",
                }}
                /* onClick={props.handlerClick.hideMenu} */
                icon={faBars}
              />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item href="#/about">
                <div className="box-buttons-container">
                  <div className="box-buttons">
                    <div onClick={toAbout} className="link-about">
                      Sobre Devise
                    </div>
                  </div>
                </div>
              </Dropdown.Item>
              <Dropdown.Item href="#/faqs" active>
                <div className="box-buttons-container">
                  <div className="box-buttons">
                    <div onClick={toFaqs} className="link">
                      Preguntas frecuentes
                    </div>
                  </div>
                </div>
              </Dropdown.Item>
              <Dropdown.Item href="#/contact-us">
                <div className="box-buttons-container">
                  <div className="box-buttons">
                    <div onClick={toContactUs} className="link">
                      Contáctanos
                    </div>
                  </div>
                </div>
              </Dropdown.Item>
              <Dropdown.Item>
                <div className="box-buttons-container">
                  <div className="box-buttons">
                    <div className="btn-cancel">
                      <Boton
                        className={"btn _btn-primary font-14"}
                        handlerClick={toRegister}
                        style={{
                          borderRadius: "8px",
                          width: "115px",
                          background: "white",
                          border: "1px solid #112e6d",
                          color: "#112e6d",
                        }}
                        label={"Registrarse"}
                      />
                    </div>
                  </div>
                </div>
              </Dropdown.Item>
              <Dropdown.Item>
                <div className="box-buttons-container">
                  <div className="box-buttons">
                    <div className="btn-logout">
                      <Boton
                        className={"btn _btn-primary font-14"}
                        style={{ borderRadius: "8px", width: "145px" }}
                        label={"Entrar a Devise"}
                        handlerClick={toLogin}
                      />
                    </div>
                  </div>
                </div>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div style={{ fontSize: "11px", marginTop: "20px" }}>
          <span>Copyright © Lincep. All right reserved</span>
        </div>
      </div>
    </div>
  );
}

export default Welcome;
