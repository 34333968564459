import { useNavigate, useParams } from "react-router-dom";
import BreadcrumbsComponent from "../../components/Breadcrumbs/BreadcrumbsComponent";
import { useCallback, useEffect, useRef, useState } from "react";
import { IsUnauthorized, httpService } from "../../helpers/ApiService";
import { StatusData } from "../../constants/status";
import { Carousel } from "react-responsive-carousel";
import BadgesProjects from "../../components/BadgesProjects/BadgesProjects/BadgesProjects";
import { classNameProyect, numFormatter } from "../../helpers/functions";
import { InvertColors } from "../../helpers/ColorsUtils";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Tab, Tabs } from "react-bootstrap";

import styles from "./PageProjectDetail.module.css";
/* import GoogleMapReact from "google-map-react"; */
import GoogleMap from "google-maps-react-markers";
import Boton from "../../components/Boton";
import errorImage from "../../assets/svg/no-image-svgrepo-com.svg";
import {
  IconBoxArea,
  IconBoxBuild,
  IconBoxCualification,
  IconBoxDevice,
  IconBoxDimensions,
  IconBoxInfo,
  IconBoxMap,
  IconBoxMapW,
  IconInvest,
} from "../../components/Icons/Icons";
import { Marker } from "../../components/MapComponents/Marker";

export default function PageProjectDetail() {
  const { id } = useParams();
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const percent = 35;
  const totalInversion = 2000150350;
  var nf = new Intl.NumberFormat("es-CO");
  /*********Para Pruebas */
  const [estadoAprobacion, setEstadoAprobacion] = useState(null);
  const [images, setImages] = useState([]);
  const [initialData, setInitialData] = useState(null);
  const [loadProject, setloadProject] = useState(true);
  const textEstados = StatusData;
  const [paso, setPaso] = useState(0);
  const mapRef = useRef(null);
  const [mapReady, setMapReady] = useState(false);
  const onGoogleApiLoaded = ({ map, maps }) => {
    mapRef.current = map;
    console.log("onGoogleApiLoaded...");
    setMapReady(true);
  };
  const customStatusFormatter = (currentSlide, totalSlides) => {
    return `${currentSlide} de ${totalSlides}`; // Personaliza el texto según tus necesidades
  };

  /*   const Marker = ({ text }) => (
    <div style={{ width: "max-content", transform: "translate(-50%,-50%)" }}>
      <label style={{
        position: "absolute",
        bottom: "calc(100% + 0px)",
        textAlign: "center",
        left:" 50%",
        transform: "translate(-50%)",
        marginBottom:"0",
        width: "max-content",
        fontWeight:"700",
        color:"var(--secondarycolorpalette-lightblue-darker)",
        WebkitTextStrokeColor:"white"

      }}>{text}</label>
      <IconBoxMapW
        
        fillOpacity="0.0"
        colorIcon={"#1b4ec1"}
        height={"40px"}
        width={"40px"}
      />
    </div>
  ); */
  useEffect(() => {
    getInitialData();
  }, [id]);
  const getInitialData = useCallback(async () => {
    const url = `asset/retrieve/${id}/`;
    setLoader(true);
    return httpService(url)
      .get()
      .then(
        /* async */ (res) => {
          if (res instanceof Object) {
            if (res && res.id) {
              console.log("Result setInitialData:", res);
              setInitialData(res);
              //showMessages
              if (textEstados[res.estado_aprobacion]) {
                const estado_aprobacion = res.estado_aprobacion;
                const _paso = textEstados[res.estado_aprobacion].paso;
                setPaso(_paso);

                if (
                  paso <= 2.1 &&
                  estado_aprobacion !== "ACTIVO_APROBADO_SPONSOR"
                ) {
                } else {
                }
              } else {
              }

              if (
                res &&
                res.estado_aprobacion &&
                estadoAprobacion !== res.estado_aprobacion
              ) {
                /* alert("estadoAprobacion changed...") */
              }
              let _images = [];

              if (res.imagen_1) {
                _images.push(res.imagen_1 );
              }
              if (res.imagen_2) {
                _images.push(res.imagen_2);
              }

              if (res.imagen_3) {
                _images.push(res.imagen_3);
              }
              if (res.imagen_4) {
                _images.push(res.imagen_4);
              }
              if (res.imagen_5) {
                _images.push(res.imagen_5);
              }
              console.log("Result setImages:", _images);
              setImages(_images);
              setloadProject(true);
            } else {
              console.log("Result empty array:", res);
            }
          }
          return res;
        }
      )
      .catch((err) => {
        console.error("XXXX Response status:", err);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }
        if (err && err.status === 404) {
          setloadProject(false);
        }
        return;
      })
      .finally(() => {
        setLoader(false);
      });
  });
  /*********Para Pruebas */
  const onMarkerClick = (ev) => {
    console.log("Page onMarkerClick", ev);
  };

  const [reloadCarousel, setReloadCarousel] = useState(false);
  const handleImageError = (index) => {
    console.log("Image error", index);
    const tempImages = JSON.parse(JSON.stringify(images));
    tempImages[index] = null;
    setImages(tempImages);
    setReloadCarousel(true);
    setTimeout(() => {
      setReloadCarousel(false);
    }, 1);
  };

  return (
    <div className="page-referals container-animated">
      Detalles del Proyecto {id}
      <div className="row">
        <div className="col-xl-12 col-lg-12">
          <BreadcrumbsComponent params={id} />
        </div>
      </div>
      <div className="row mt-4 custom-col-1">
        <div className="col-md-12">
          <div className="card-container ">
            <div className="row custom w-100">
              <div className="col-md-6 custom">
                {images && (
                  <Carousel
                    autoPlay={true}
                    infiniteLoop={true}
                    selectedItem={images.length ? images.length : 0}
                    showArrows={
                      true
                    } /* onChange={onChange} onClickItem={onClickItem} onClickThumb={onClickThumb} */
                    autoFocus={true}
                    statusFormatter={customStatusFormatter}
                    /* renderThumbs={"100px"} */
                    /* renderThumbs={renderThumbs} */
                  >
                    {/* <div><img src="https://plus.unsplash.com/premium_photo-1684107938987-0693da2a9b37?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80" />
                        <p className="legend">Legend 1</p>
                      </div> */}
                    {images.map((img, i, arr) => {
                      if (i === arr.length - 1) {
                        // setImagesComponent(true)
                      }

                      return (
                        <div
                          className={!img ? "carousel-box-key" : ""}
                          key={`img-${i}`}
                        >
                          {!reloadCarousel && (
                            <img
                              src={img ? img : errorImage}
                              onError={() => handleImageError(i)}
                            />
                          )}
                        </div>
                      );
                    })}
                  </Carousel>
                )}
                {images.length == 0 && (
                  <div className={"carousel-box-key"}>
                    {!reloadCarousel && <img src={errorImage} />}
                  </div>
                )}
              </div>
              <div className="col-md-4 custom">
                {initialData && (
                  <div className="w-100">
                    <div style={{ display: "flex" }}>
                      <div style={{ marginLeft: "-15px" }} className="badge">
                        <BadgesProjects
                          labelStyle={{
                            background: initialData.tipo_proyecto.color,
                          }}
                          className={`${
                            classNameProyect[initialData.tipo_proyecto.nombre]
                              ? classNameProyect[
                                  initialData.tipo_proyecto.nombre
                                ]
                              : "bg-blue"
                          }`}
                          text={initialData.tipo_proyecto.nombre}
                        />
                      </div>
                    </div>
                    <div style={{ display: "flex" }}>
                      <div style={{ marginLeft: "-15px" }}>
                        <div className="table-title blue">
                          {initialData.nombre}
                        </div>
                      </div>
                    </div>

                    <div className={styles.sectionDetail}>
                      <div className={styles.pageSecurityHeadsWPaddinW}>
                        <div
                          style={{
                            fontFamily: "Poppins",
                            fontWeight: "400",
                            paddingBottom: 0,
                          }}
                          className="page--subtitle"
                        >
                          <span
                            style={{
                              textAlign: "left",
                              display: "block",
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "Poppins",
                                fontWeight: "700",
                              }}
                            >
                              ${nf.format(totalInversion)}
                            </span>
                            <span className={styles.strongStyleRight}>
                              &nbsp;{"14%"}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div style={{ display: "flex", marginLeft: "5px" }}>
                      <div
                        className="page-security-head"
                        style={{ padding: "1px 0px 0px", width: "100%" }}
                      >
                        <div
                          style={{
                            fontFamily: "Poppins",
                            fontWeight: "400",
                          }}
                          className="page--subtitle"
                        >
                          <span
                            style={{
                              textAlign: "left",
                              display: "block",
                            }}
                          >
                            <span
                              className={styles.sectionDetailFontStyleSmall}
                            >
                              Total inversión
                            </span>
                            <span
                              className={styles.sectionDetailFontStyleRight}
                            >
                              &nbsp;{"TIR E.A."}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>

                    <div style={{ display: "flex", marginLeft: "5px" }}>
                      <div
                        className="page-security-head"
                        style={{ padding: "1px 0px 0px", width: "100%" }}
                      >
                        <div
                          style={{
                            fontFamily: "Poppins",
                            fontWeight: "400",
                            paddingBottom: 0,
                          }}
                          className="page--subtitle"
                        >
                          <span
                            style={{
                              textAlign: "left",
                              display: "block",
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "Poppins",
                                fontWeight: "700",
                              }}
                            >
                              Mensual
                            </span>
                            <span className={styles.strongStyleRight}>
                              &nbsp;{"5 años"}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>

                    <div style={{ display: "flex", marginLeft: "5px" }}>
                      <div
                        className="page-security-head"
                        style={{ padding: "1px 0px 0px", width: "100%" }}
                      >
                        <div
                          style={{
                            fontFamily: "Poppins",
                            fontWeight: "400",
                          }}
                          className="page--subtitle"
                        >
                          <span className={styles.sectionDetailFontStyleLeft}>
                            <span
                              className={styles.sectionDetailFontStyleSmall}
                            >
                              Pago de rendimiento
                            </span>
                            <span
                              className={styles.sectionDetailFontStyleRight}
                            >
                              &nbsp;{"Tenencia sugerida"}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className={[styles.section]}>
                      <ProgressBar className={styles.statusBar}>
                        <ProgressBar
                          className="custom-progress"
                          color="blue"
                          now={percent}
                          label={`${percent}%`}
                          key={1}
                        />
                        <ProgressBar
                          className="custom-progress-negative"
                          now={100 - percent}
                          key={2}
                        />
                      </ProgressBar>
                    </div>
                    <div style={{ display: "flex", marginLeft: "5px" }}>
                      <div
                        className="page-security-head"
                        style={{ padding: "1px 0px 0px", width: "100%" }}
                      >
                        <div
                          style={{
                            fontFamily: "Poppins",
                            fontWeight: "400",
                            paddingBottom: 0,
                          }}
                          className="page--subtitle"
                        >
                          <span
                            style={{
                              textAlign: "left",
                              display: "block",
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "Poppins",
                                fontWeight: "700",
                              }}
                            >
                              ${nf.format((totalInversion * percent) / 100)}
                            </span>
                            <span style={{ float: "right", fontWeight: 700 }}>
                              &nbsp;{percent}%
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className={styles.sectionDetail}>
                      <div className={styles.pageSecurityHeadsWPaddinW}>
                        <div className="page--subtitle">
                          <span className={styles.sectionDetailFontStyleLeft}>
                            <span
                              className={styles.sectionDetailFontStyleSmall}
                            >
                              Total vendido de:{" "}
                              <strong className={styles.strongStyle}>
                                {nf.format(totalInversion)}
                              </strong>
                            </span>
                            <span
                              className={styles.sectionDetailFontStyleRight}
                            >
                              &nbsp;{"% recaudado"}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className={styles.mapContainer}>
                      {initialData && (
                        <GoogleMap
                          apiKey="AIzaSyB6xXv_hOtPPBROjIp8mjUdEOCXFc-EX6U" // AIzaSyB6xXv_hOtPPBROjIp8mjUdEOCXFc-EX6U juan->AIzaSyCvMpzpdq6uRtxDvpSTEWwZml-KIGFyFLw
                          defaultCenter={{
                            lat: initialData.latitud,
                            lng: initialData.longitud,
                          }}
                          defaultZoom={14}
                          mapMinHeight="200px"
                          onGoogleApiLoaded={onGoogleApiLoaded}
                          onChange={(map) => console.log("Map moved", map)}
                        >
                          <Marker
                            lat={initialData.latitud}
                            lng={initialData.longitud}
                            markerId={initialData.nombre}
                            text={initialData.nombre}
                            handleClick={onMarkerClick} // you need to manage this prop on your Marker component!
                            //draggable={true}
                            // onDragStart={(e, { latLng }) => {}}
                            // onDrag={(e, { latLng }) => {}}
                            // onDragEnd={(e, { latLng }) => {}}
                          />
                        </GoogleMap>
                      )}
                    </div>
                    <div
                      style={{ marginTop: "20px", widows: "100%" }}
                      className={styles.sectionDetail}
                    >
                      <Boton
                        style={{ borderRadius: "10px", width: "100%" }}
                        disabled={false}
                        className={"btn _btn-primary font-14"}
                        label={"Quiero Invertir"}
                        borderRadius="10px"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={"col-sm-12 col-md-12"}>
          <div className="card-container mt-4">
            <div className="row w-100">
              <div className="col-md-12"></div>
              <div className="col-md-8 col-lg-8 mt-4">
                <div
                  className="page-security-head"
                  style={{ padding: "5px 0px 0px" }}
                >
                  <div className="page-security-subtitle">
                    Sobre el proyecto
                  </div>
                </div>
                <Tabs
                  defaultActiveKey="informacion-proyecto"
                  id="uncontrolled-tab-example"
                >
                  <Tab
                    style={{
                      border: "1px solid #eeeeee",
                      marginTop: "-5px",
                      borderBottomRightRadius: "10px",
                      borderBottomLeftRadius: "10px",
                      padding: "10px",
                    }}
                    eventKey="informacion-proyecto"
                    title="Información del proyecto"
                  >
                    <div
                      style={{ textAlign: "justify" }}
                      className="tab-container"
                    >
                      <div
                        className="page-security-head"
                        style={{ padding: "5px 0px 0px" }}
                      >
                        <div className="page-security-subtitle">
                          <IconBoxDevice
                            style={{ transform: "translate(0px, 7px)" }}
                            colorIcon="#1b4ec1"
                            height="15px"
                            width="15px"
                          />
                          <div style={{ marginLeft: "10px" }}>
                            Sobre el Proyecto
                          </div>
                        </div>
                      </div>
                      <div className={styles.infoTecBoxText}>
                        {initialData?.descripcion_proyecto}
                      </div>
                    </div>
                    <div
                      style={{ textAlign: "justify", marginTop: "15px" }}
                      className="tab-container"
                    >
                      <div
                        className="page-security-head"
                        style={{ padding: "5px 0px 0px" }}
                      >
                        <div className="page-security-subtitle">
                          <IconBoxMapW
                            style={{ transform: "translate(0px, 4px)" }}
                            colorIcon={"#1b4ec1"}
                            height={"20px"}
                            width={"20px"}
                          />
                          <div style={{ marginLeft: "10px" }}>
                            Sobre la Ubicación
                          </div>
                        </div>
                      </div>
                      <div className={styles.infoTecBoxText}>
                        {initialData?.descripcion_ubicacion}
                      </div>
                    </div>
                    <div
                      style={{ textAlign: "justify", marginTop: "15px" }}
                      className="tab-container"
                    >
                      <div
                        className="page-security-head"
                        style={{ padding: "5px 0px 0px" }}
                      >
                        <div className="page-security-subtitle">
                          <IconInvest
                            style={{ transform: "translate(0px, 5px)" }}
                            colorIcon={"#1b4ec1"}
                            height={"18px"}
                            width={"25px"}
                          />
                          <div style={{ marginLeft: "10px" }}>
                            Tipo de Proyecto
                          </div>
                        </div>
                      </div>
                      <div
                        style={{ display: "flex" }}
                        className={styles.infoTecBoxText}
                      >
                        <BadgesProjects
                          labelStyle={{
                            background: initialData?.tipo_proyecto.color,
                          }}
                          className={`${
                            classNameProyect[initialData?.tipo_proyecto.nombre]
                              ? classNameProyect[
                                  initialData?.tipo_proyecto.nombre
                                ]
                              : "bg-blue"
                          }`}
                          text={initialData?.tipo_proyecto.nombre}
                        />
                      </div>
                    </div>
                    <div
                      style={{ textAlign: "justify", marginTop: "15px" }}
                      className="tab-container"
                    >
                      <div
                        className="page-security-head"
                        style={{ padding: "5px 0px 0px" }}
                      >
                        <div className="page-security-subtitle">
                          <IconBoxInfo
                            style={{ transform: "translate(0px, 5px)" }}
                            colorIcon={"#1b4ec1"}
                            height={"20px"}
                            width={"25px"}
                          />
                          <div style={{ marginLeft: "10px" }}>
                            Información Técnica
                          </div>
                        </div>
                      </div>
                      <div className={styles.infoTec}>
                        <div className={styles.infoTecBox}>
                          <div className="icon-box" style={{ float: "left" }}>
                            <IconBoxMap
                              style={{ transform: "translate(0px, 4px)" }}
                              height={"20px"}
                              width={"20px"}
                            />
                          </div>
                          <div>
                            <div className={styles.infoTecBoxTitle}>
                              Ubicación
                            </div>
                            <div className={styles.infoTecBoxText}>
                              {initialData?.direccion}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{ marginTop: "10px" }}
                        className={styles.infoTec}
                      >
                        <div className={styles.infoTecBox}>
                          <div className="icon-box" style={{ float: "left" }}>
                            <IconBoxDimensions
                              style={{ transform: "translate(0px, 0px)" }}
                              colorIcon={"#1b4ec1"}
                              height={"30px"}
                              width={"30px"}
                            />
                          </div>
                          <div>
                            <div className={styles.infoTecBoxTitle}>
                              Dimenciones del lote
                            </div>
                            <div className={styles.infoTecBoxText}>
                              {initialData?.area_lote_m2} m<sup>2</sup>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{ marginTop: "10px" }}
                        className={styles.infoTec}
                      >
                        <div className={styles.infoTecBox}>
                          <div className="icon-box" style={{ float: "left" }}>
                            <IconBoxArea
                              style={{ transform: "translate(0px, 0px)" }}
                              colorIcon={"#1b4ec1"}
                              height={"24px"}
                              width={"24px"}
                            />
                          </div>
                          <div>
                            <div className={styles.infoTecBoxTitle}>
                              Area construida
                            </div>
                            <div className={styles.infoTecBoxText}>
                              {initialData?.area_construida_m2} m<sup>2</sup>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{ marginTop: "10px" }}
                        className={styles.infoTec}
                      >
                        <div className={styles.infoTecBox}>
                          <div className="icon-box" style={{ float: "left" }}>
                            <IconBoxBuild
                              style={{ transform: "translate(0px, 0px)" }}
                              height={"30px"}
                              width={"30px"}
                            />
                          </div>
                          <div>
                            <div className={styles.infoTecBoxTitle}>
                              Año construcción
                            </div>
                            <div className={styles.infoTecBoxText}>
                              {initialData?.ano_construccion}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div style={{marginTop:"10px"}} className={styles.infoTec}>
                        <div className={styles.infoTecBox}>
                          <div className={styles.infoTecBoxTitle}>
                            Plazo de contrato
                          </div>
                          <div className={styles.infoTecBoxText}>
                            {initialData.ano_construccion} 
                          </div>
                        </div>
                      </div> */}
                      <div
                        style={{ marginTop: "10px" }}
                        className={styles.infoTec}
                      >
                        <div className={styles.infoTecBox}>
                          <div className="icon-box" style={{ float: "left" }}>
                            <IconBoxCualification
                              style={{ transform: "translate(0px, 0px)" }}
                              height={"30px"}
                              width={"30px"}
                            />
                          </div>
                          <div>
                            <div className={styles.infoTecBoxTitle}>
                              Tipo de activo
                            </div>
                            <div className={styles.infoTecBoxText}>
                              {initialData?.categoria.nombre}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab>
                  <Tab eventKey="arrendatario" title="Arrendatario"></Tab>
                  <Tab eventKey="operador" title="Operador"></Tab>
                  <Tab eventKey="fiducia" title="Fiducia"></Tab>
                  <Tab eventKey="t_c" title="T&C"></Tab>
                </Tabs>
              </div>
              <div className="col-md-4 col-lg-4 mt-4">
                <div
                  className="page-security-head"
                  style={{ padding: "5px 0px 0px" }}
                >
                  <div className="page-security-subtitle">Información</div>
                  <Tabs
                    defaultActiveKey="sobre_inversion"
                    id="uncontrolled-tab-example"
                  >
                    <Tab
                      style={{
                        border: "1px solid #eeeeee",
                        marginTop: "-5px",
                        borderBottomRightRadius: "10px",
                        borderBottomLeftRadius: "10px",
                      }}
                      eventKey="sobre_inversion"
                      title="Sobre inversion"
                    >
                      <div
                        style={{ textAlign: "justify", padding: "10px" }}
                        className="tab-container"
                      >
                        <div style={{ display: "flex", marginLeft: "5px" }}>
                          <div
                            className="page-security-head"
                            style={{ padding: "1px 0px 0px", width: "100%" }}
                          >
                            <div
                              style={{
                                fontFamily: "Poppins",
                                fontWeight: "400",
                                paddingBottom: 0,
                              }}
                              className="page--subtitle"
                            >
                              <span
                                style={{
                                  textAlign: "left",
                                  display: "block",
                                }}
                              >
                                <span
                                  style={{
                                    fontFamily: "Poppins",
                                    fontWeight: "700",
                                  }}
                                >
                                  ${nf.format((totalInversion * percent) / 100)}
                                </span>
                                <div className="page--subtitle">
                                  <span
                                    style={{ marginTop: "-5px" }}
                                    className={
                                      styles.sectionDetailFontStyleLeft
                                    }
                                  >
                                    <span
                                      className={
                                        styles.sectionDetailFontStyleSmall
                                      }
                                    >
                                      Total recaudado de:{" "}
                                      <strong className={styles.strongStyle}>
                                        {nf.format(totalInversion)}
                                      </strong>
                                    </span>
                                  </span>
                                </div>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        style={{ textAlign: "justify", padding: "10px" }}
                        className="tab-container"
                      >
                        <div style={{ display: "flex", marginLeft: "5px" }}>
                          <div
                            className="page-security-head"
                            style={{ padding: "1px 0px 0px", width: "100%" }}
                          >
                            <div
                              style={{
                                fontFamily: "Poppins",
                                fontWeight: "400",
                                paddingBottom: 0,
                              }}
                              className="page--subtitle"
                            >
                              <span
                                style={{
                                  textAlign: "left",
                                  display: "block",
                                }}
                              >
                                <span
                                  style={{
                                    fontFamily: "Poppins",
                                    fontWeight: "700",
                                  }}
                                >
                                  203
                                </span>
                                <div className="page--subtitle">
                                  <span
                                    style={{ marginTop: "-5px" }}
                                    className={
                                      styles.sectionDetailFontStyleLeft
                                    }
                                  >
                                    <span
                                      className={
                                        styles.sectionDetailFontStyleSmall
                                      }
                                    >
                                      Inversores{" "}
                                    </span>
                                  </span>
                                </div>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        style={{ textAlign: "justify", padding: "10px" }}
                        className="tab-container"
                      >
                        <div style={{ display: "flex", marginLeft: "5px" }}>
                          <div
                            className="page-security-head"
                            style={{ padding: "1px 0px 0px", width: "100%" }}
                          >
                            <div
                              style={{
                                fontFamily: "Poppins",
                                fontWeight: "400",
                                paddingBottom: 0,
                              }}
                              className="page--subtitle"
                            >
                              <span
                                style={{
                                  textAlign: "left",
                                  display: "block",
                                }}
                              >
                                <span
                                  style={{
                                    fontFamily: "Poppins",
                                    fontWeight: "700",
                                  }}
                                >
                                  $
                                  {nf.format(
                                    totalInversion -
                                      (totalInversion * percent) / 100
                                  )}
                                </span>
                                <div className="page--subtitle">
                                  <span
                                    style={{ marginTop: "-5px" }}
                                    className={
                                      styles.sectionDetailFontStyleLeft
                                    }
                                  >
                                    <span
                                      className={
                                        styles.sectionDetailFontStyleSmall
                                      }
                                    >
                                      Total recaudado de:{" "}
                                      <strong className={styles.strongStyle}>
                                        {nf.format(totalInversion)}
                                      </strong>
                                    </span>
                                  </span>
                                </div>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab>
                  </Tabs>
                </div>

                <div
                  className="page-security-head"
                  style={{ padding: "5px 0px 0px" }}
                >
                  <Tabs
                    defaultActiveKey="sobre_rentabilidad"
                    id="uncontrolled-tab-example"
                  >
                    <Tab
                      style={{
                        border: "1px solid #eeeeee",
                        marginTop: "-5px",
                        borderBottomRightRadius: "10px",
                        borderBottomLeftRadius: "10px",
                      }}
                      eventKey="sobre_rentabilidad"
                      title="Sobre Rentabilidad"
                    >
                      <div
                        style={{ textAlign: "justify", padding: "10px" }}
                        className="tab-container"
                      >
                        <div style={{ display: "flex", marginLeft: "5px" }}>
                          <div
                            className="page-security-head"
                            style={{ padding: "1px 0px 0px", width: "100%" }}
                          >
                            <div
                              style={{
                                fontFamily: "Poppins",
                                fontWeight: "400",
                                paddingBottom: 0,
                              }}
                              className="page--subtitle"
                            >
                              <span
                                style={{
                                  textAlign: "left",
                                  display: "block",
                                }}
                              >
                                <span
                                  style={{
                                    fontFamily: "Poppins",
                                    fontWeight: "700",
                                  }}
                                >
                                  ${nf.format(100000000)} + IVA
                                </span>
                                <div className="page--subtitle">
                                  <span
                                    style={{ marginTop: "-5px" }}
                                    className={
                                      styles.sectionDetailFontStyleLeft
                                    }
                                  >
                                    <span
                                      className={
                                        styles.sectionDetailFontStyleSmall
                                      }
                                    >
                                      Renta mensual del activo
                                    </span>
                                  </span>
                                </div>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        style={{ textAlign: "justify", padding: "10px" }}
                        className="tab-container"
                      >
                        <div style={{ display: "flex", marginLeft: "5px" }}>
                          <div
                            className="page-security-head"
                            style={{ padding: "1px 0px 0px", width: "100%" }}
                          >
                            <div
                              style={{
                                fontFamily: "Poppins",
                                fontWeight: "400",
                                paddingBottom: 0,
                              }}
                              className="page--subtitle"
                            >
                              <span
                                style={{
                                  textAlign: "left",
                                  display: "block",
                                }}
                              >
                                <span
                                  style={{
                                    fontFamily: "Poppins",
                                    fontWeight: "700",
                                  }}
                                >
                                  {nf.format(30000)} + IVA
                                </span>
                                <div className="page--subtitle">
                                  <span
                                    style={{ marginTop: "-5px" }}
                                    className={
                                      styles.sectionDetailFontStyleLeft
                                    }
                                  >
                                    <span
                                      className={
                                        styles.sectionDetailFontStyleSmall
                                      }
                                    >
                                      Renta por m<sup>2</sup> del activo
                                    </span>
                                  </span>
                                </div>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        style={{ textAlign: "justify", padding: "10px" }}
                        className="tab-container"
                      >
                        <div style={{ display: "flex", marginLeft: "5px" }}>
                          <div
                            className="page-security-head"
                            style={{ padding: "1px 0px 0px", width: "100%" }}
                          >
                            <div
                              style={{
                                fontFamily: "Poppins",
                                fontWeight: "400",
                                paddingBottom: 0,
                              }}
                              className="page--subtitle"
                            >
                              <span
                                style={{
                                  textAlign: "left",
                                  display: "block",
                                }}
                              >
                                <span
                                  style={{
                                    fontFamily: "Poppins",
                                    fontWeight: "700",
                                  }}
                                >
                                  IPC + 1%
                                </span>
                                <div className="page--subtitle">
                                  <span
                                    style={{ marginTop: "-5px" }}
                                    className={
                                      styles.sectionDetailFontStyleLeft
                                    }
                                  >
                                    <span
                                      className={
                                        styles.sectionDetailFontStyleSmall
                                      }
                                    >
                                      Incremento anual
                                    </span>
                                  </span>
                                </div>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        style={{ textAlign: "justify", padding: "10px" }}
                        className="tab-container"
                      >
                        <div style={{ display: "flex", marginLeft: "5px" }}>
                          <div
                            className="page-security-head"
                            style={{ padding: "1px 0px 0px", width: "100%" }}
                          >
                            <div
                              style={{
                                fontFamily: "Poppins",
                                fontWeight: "400",
                                paddingBottom: 0,
                              }}
                              className="page--subtitle"
                            >
                              <span
                                style={{
                                  textAlign: "left",
                                  display: "block",
                                }}
                              >
                                <span
                                  style={{
                                    fontFamily: "Poppins",
                                    fontWeight: "700",
                                  }}
                                >
                                  10 años
                                </span>
                                <div className="page--subtitle">
                                  <span
                                    style={{ marginTop: "-5px" }}
                                    className={
                                      styles.sectionDetailFontStyleLeft
                                    }
                                  >
                                    <span
                                      className={
                                        styles.sectionDetailFontStyleSmall
                                      }
                                    >
                                      Plazo del contrato
                                    </span>
                                  </span>
                                </div>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        style={{ textAlign: "justify", padding: "10px" }}
                        className="tab-container"
                      >
                        <div style={{ display: "flex", marginLeft: "5px" }}>
                          <div
                            className="page-security-head"
                            style={{ padding: "1px 0px 0px", width: "100%" }}
                          >
                            <div
                              style={{
                                fontFamily: "Poppins",
                                fontWeight: "400",
                                paddingBottom: 0,
                              }}
                              className="page--subtitle"
                            >
                              <span
                                style={{
                                  textAlign: "left",
                                  display: "block",
                                }}
                              >
                                <span
                                  style={{
                                    fontFamily: "Poppins",
                                    fontWeight: "700",
                                  }}
                                >
                                  7.5 - 8.5%
                                </span>
                                <div className="page--subtitle">
                                  <span
                                    style={{ marginTop: "-5px" }}
                                    className={
                                      styles.sectionDetailFontStyleLeft
                                    }
                                  >
                                    <span
                                      className={
                                        styles.sectionDetailFontStyleSmall
                                      }
                                    >
                                      TIR E.A
                                    </span>
                                  </span>
                                </div>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab>

                    <Tab
                      style={{
                        border: "1px solid #eeeeee",
                        marginTop: "-5px",
                        borderBottomRightRadius: "10px",
                        borderBottomLeftRadius: "10px",
                      }}
                      eventKey="simulador"
                      title="Simulador"
                    >
                      <div
                        style={{ textAlign: "justify", padding: "10px" }}
                        className="tab-container"
                      >
                        <div style={{ display: "flex", marginLeft: "5px" }}>
                          <div
                            className="page-security-head"
                            style={{ padding: "1px 0px 0px", width: "100%" }}
                          >
                            <div
                              style={{
                                fontFamily: "Poppins",
                                fontWeight: "400",
                                paddingBottom: 0,
                              }}
                              className="page--subtitle"
                            >
                              <span
                                style={{
                                  textAlign: "left",
                                  display: "block",
                                }}
                              >
                                <span
                                  style={{
                                    fontFamily: "Poppins",
                                    fontWeight: "700",
                                  }}
                                >
                                  ${nf.format((totalInversion * percent) / 100)}
                                </span>
                                <div className="page--subtitle">
                                  <span
                                    style={{ marginTop: "-5px" }}
                                    className={
                                      styles.sectionDetailFontStyleLeft
                                    }
                                  >
                                    <span
                                      className={
                                        styles.sectionDetailFontStyleSmall
                                      }
                                    >
                                      Total recaudado de:{" "}
                                      <strong className={styles.strongStyle}>
                                        {nf.format(totalInversion)}
                                      </strong>
                                    </span>
                                  </span>
                                </div>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        style={{ textAlign: "justify", padding: "10px" }}
                        className="tab-container"
                      >
                        <div style={{ display: "flex", marginLeft: "5px" }}>
                          <div
                            className="page-security-head"
                            style={{ padding: "1px 0px 0px", width: "100%" }}
                          >
                            <div
                              style={{
                                fontFamily: "Poppins",
                                fontWeight: "400",
                                paddingBottom: 0,
                              }}
                              className="page--subtitle"
                            >
                              <span
                                style={{
                                  textAlign: "left",
                                  display: "block",
                                }}
                              >
                                <span
                                  style={{
                                    fontFamily: "Poppins",
                                    fontWeight: "700",
                                  }}
                                >
                                  203
                                </span>
                                <div className="page--subtitle">
                                  <span
                                    style={{ marginTop: "-5px" }}
                                    className={
                                      styles.sectionDetailFontStyleLeft
                                    }
                                  >
                                    <span
                                      className={
                                        styles.sectionDetailFontStyleSmall
                                      }
                                    >
                                      Inversores{" "}
                                    </span>
                                  </span>
                                </div>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        style={{ textAlign: "justify", padding: "10px" }}
                        className="tab-container"
                      >
                        <div style={{ display: "flex", marginLeft: "5px" }}>
                          <div
                            className="page-security-head"
                            style={{ padding: "1px 0px 0px", width: "100%" }}
                          >
                            <div
                              style={{
                                fontFamily: "Poppins",
                                fontWeight: "400",
                                paddingBottom: 0,
                              }}
                              className="page--subtitle"
                            >
                              <span
                                style={{
                                  textAlign: "left",
                                  display: "block",
                                }}
                              >
                                <span
                                  style={{
                                    fontFamily: "Poppins",
                                    fontWeight: "700",
                                  }}
                                >
                                  $
                                  {nf.format(
                                    totalInversion -
                                      (totalInversion * percent) / 100
                                  )}
                                </span>
                                <div className="page--subtitle">
                                  <span
                                    style={{ marginTop: "-5px" }}
                                    className={
                                      styles.sectionDetailFontStyleLeft
                                    }
                                  >
                                    <span
                                      className={
                                        styles.sectionDetailFontStyleSmall
                                      }
                                    >
                                      Total recaudado de:{" "}
                                      <strong className={styles.strongStyle}>
                                        {nf.format(totalInversion)}
                                      </strong>
                                    </span>
                                  </span>
                                </div>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
