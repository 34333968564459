import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { httpService } from "../../helpers/ApiService";
import imagen from "../../assets/img1.jpeg";
import SetNewPasswordForm from "../../components/SetNewPassword/SetNewPasswordForm";

function SetNewPassword() {
  useEffect(() => {
    const height = document.getElementsByClassName("column-rigth")[0];
    const element = document.getElementsByClassName("login-img")[0];

    new Promise((resolve) =>
      setTimeout(() => {
        element.style.height = height.clientHeight + "px";

        resolve(true);
      }, 10)
    );

    function handleResize() {
      let height = document.getElementsByClassName("column-rigth")[0];
      if (height) {
        //console.log("resized to: ", height.clientHeight, "x", height.clientWidth);
      }
    }

    window.addEventListener("resize", handleResize);
  });
  let { slug } = useParams();
  const [slugCode, setSlugCode] = useState("");
  const [checkErrors, setCheckErrors] = useState("");
  const [isLoad, setIsLoad] = useState(false);
  useEffect(() => {
    if (slug) {
      console.log("slug:", slug);
      setIsLoad(false);
      checkSlug();
    }
  }, []);

  const checkSlug = () => {
    //password/verify/code/<slug:slug>/
    const url = `/user/password/verify/code/${slug}/`;
    const formData = new FormData();
    formData.append("slug", slug);

    return httpService(url, formData)
      .get()
      .then(async (res) => {
        console.log("saveData:", res);
        setSlugCode(slug);
        return res;
      })
      .catch((err) => {
        if (err && err.raw && err.raw.data) {
          let errors = [];
          err.raw.data.errors.forEach((detail) => {
            console.error("XXXX Response status:", detail);
            errors.push(detail.detail);
          });
          console.error("XXXX Response status:", errors);
          setCheckErrors(errors.join(", "));
          setSlugCode("");
        }

        return err;
      })
      .finally(() => {
        setIsLoad(true);
      });
  };

  return (
    <div className="ResetPassword container-animated">
      <div className="login-container">
        <div className="container">
          <div className="column-left box">
            <div className="login-img">
              <div className="img-container">
                <img src={imagen} />
              </div>
            </div>
          </div>
          <div className="column-rigth box">
          <SetNewPasswordForm checkErrors={checkErrors} isLoad={isLoad} slug={slugCode}/>
           
          </div>
        </div>
      </div>
    </div>
  );
}

export default SetNewPassword;

