import { useEffect, useState } from "react";
import BreadcrumbsComponent from "../../components/Breadcrumbs/BreadcrumbsComponent";
import { CardBlog } from "../../components/CardBlog/CardBlog";
import MenuBlog from "../../components/CardMenuBlog/CardMenuBlog";
import { CategoryTag } from "../../components/CategoryTag/CategoryTag";
import "./PageAcademy.css";
import { IsUnauthorized, httpService } from "../../helpers/ApiService";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";


export default function PageAcademy({ hideBreadcrumbs }) {
  const navigate = useNavigate();
  const [CODE, setCODE] = useState("todo");
  const [renderBtn, setRenderBtn] = useState(false);
  const [renderCards, setRenderCards] = useState(false);
  const [buttonsFinal, setButtonsFinal] = useState(false);

  const [paginationData, setPaginationData] = useState({
    count:1,
    next:null,
    previous:null
  });
  const itemsPerPage = 5;
  const [_buttons, set_Buttons] = useState([
    {
      active: true,
      color: "#d2c7f7",
      descripcion: "Economía de Hogar descripción",
      id: "todo",
      imagen: null,
      nombre: "Todos",
    },
  ]);
  const [cards, setCards] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const [page, setPage] = useState(1);
  const setCode = (code) => {
    setCODE(code);
    const loadData = async () => {      
      console.log("Page set Code----------------->", code);
      if (code != "todo") {
        await getArticulosList(code,0);
      } else  {
        //alert("setCODE-------------> código:"+"("+CODE+")"  )
        await getArticulosList();
      }
    };
    loadData();
  };
  
  useEffect(() => {
    console.log("_cards:", cards);
  }, [cards]);
  
  useEffect(() => {
    console.log("_buttons:", _buttons);
    setRenderBtn(true);
    //getArticulosList();
  }, [_buttons]);
  
  useEffect(() => {
    const loadData = async () => {
      await getCategoryList();
      //await getArticulosList();
    };
    loadData();
  }, []);

  const getCategoryList = async (active = true) => {
    setRenderBtn(false);
    return httpService("/academia/categoria/api/list/")
      .get()
      .then(async (res) => {
        let btns = [];
        console.log("XXXX Response arrayConNuevoAtributo:0", res);
        btns = [
          {
            active: active,
            color: "#d2c7f7",
            descripcion: "Economía de Hogar descripción",
            id: "todo",
            imagen: null,
            nombre: "Todos",
            carbBlog: [],
          },
          ...res,
        ];
        console.log("XXXX Response arrayConNuevoAtributo:1", btns);

        let newArray = await btns.map((objeto) => ({
          ...objeto,
          ["active"]: objeto.active ? objeto.active : false,
          ["carbBlog"]: [],
        }));
        console.log("XXXX Response arrayConNuevoAtributo:2", newArray);
        set_Buttons(newArray);
        setRenderBtn(true);
        return res;
      })
      .catch((err) => {
        console.error("XXXX Response status:", err.status);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }
        return;
      });
  };
  ///academia/articulo/api/list
  const getArticulosList = async (categoria,_page = 0) => {
    setRenderCards(false);
    let url = `/academia/articulo/api/list/?page=${_page <= 0 ? 1: _page }`;
    if (categoria) {
      url = `/academia/articulo/api/list/?categoria=${categoria}&page=${_page <= 0 ? 1: _page }`;
    }
    return httpService(url)
      .get()
      .then(async (res) => {
        let _cards = [];
        let _paginationData = {
          count:res.count,
          next:res.next,
          previous:res.previous
        }
        setPaginationData(_paginationData)
        _cards = res.results;
        console.log("_cards:------------------->", _cards);
        setCards(_cards);
        setRenderCards(true);
        setRenderBtn(true);
        return res;
      })
      .catch((err) => {
        console.error("XXXX Response status:", err.status);
        if (IsUnauthorized(err, navigate)) {
          /* notify("Sesión expirada",{type:"error"}) */
        }
        return;
      });
  };

  const buttons = [
    {
      id: "todo",
      nombre: "Todo",
      active: true,
      carbBlog: [
        {
          item1: "Libertad Financiera, claves para 2023",
          item2: (
            <CategoryTag
              className="categoryTag"
              text="Mis finanzas"
              bg="category-tag-color-3"
            />
          ),
          item3:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
          image:
            "https://static.overlay-tech.com/assets/94264c41-b446-493d-a36f-f6aee83ea34e.png",
        },
        {
          item1: "Beneficios de invertir en el sector en el 2023",
          item2: (
            <CategoryTag
              className="categoryTag"
              text="Mi Sector"
              bg="category-tag-color-4"
            />
          ),
          item3:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
          image:
            "https://static.overlay-tech.com/assets/94264c41-b446-493d-a36f-f6aee83ea34e.png",
        },
        {
          item1: "Recarga tu cuenata con PSE fácil",
          item2: (
            <CategoryTag
              className="categoryTag"
              text="Devise Lover"
              bg="category-tag-color-2"
            />
          ),
          item3:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
          image:
            "https://static.overlay-tech.com/assets/94264c41-b446-493d-a36f-f6aee83ea34e.png",
        },
        {
          item1: "Comenzando en el mundo de las inversiones",
          item2: (
            <CategoryTag
              className="categoryTag"
              text="Mis finanzas"
              bg="category-tag-color-3"
            />
          ),
          item3:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
          image:
            "https://static.overlay-tech.com/assets/94264c41-b446-493d-a36f-f6aee83ea34e.png",
        },
        {
          item1: "Nuevos proyectos de inversión",
          item2: (
            <CategoryTag
              className="categoryTag"
              text="Mi Sector"
              bg="category-tag-color-4"
            />
          ),
          item3:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
          image:
            "https://static.overlay-tech.com/assets/94264c41-b446-493d-a36f-f6aee83ea34e.png",
        },
        {
          item1: "Crecimiento del sector para 2023",
          item2: (
            <CategoryTag
              className="categoryTag"
              text="Mi Sector"
              bg="category-tag-color-4"
            />
          ),
          item3:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
          image:
            "https://static.overlay-tech.com/assets/94264c41-b446-493d-a36f-f6aee83ea34e.png",
        },
        {
          item1: "¿Qué son los Devide Coin?",
          item2: (
            <CategoryTag
              className="categoryTag"
              text="Devise Lover"
              bg="category-tag-color-2"
            />
          ),
          item3:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
          image:
            "https://static.overlay-tech.com/assets/94264c41-b446-493d-a36f-f6aee83ea34e.png",
        },
      ],
    },
    {
      id: "1",
      nombre: "Devise Lover",
      active: false,
      carbBlog: [
        {
          item1: "Recarga tu Cuenta con PSE fácilmante",
          item2: (
            <CategoryTag
              className="categoryTag"
              text="Devise Lover"
              bg="category-tag-color-2"
            />
          ),
          item3:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
          image:
            "https://static.overlay-tech.com/assets/94264c41-b446-493d-a36f-f6aee83ea34e.png",
        },
        {
          item1: "¿Qué son los Devide Coin?",
          item2: (
            <CategoryTag
              className="categoryTag"
              text="Devise Lover"
              bg="category-tag-color-2"
            />
          ),
          item3:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
          image:
            "https://static.overlay-tech.com/assets/94264c41-b446-493d-a36f-f6aee83ea34e.png",
        },
      ],
    },
    {
      id: "2",
      nombre: "Mis Finanzas",
      active: false,
      carbBlog: [
        {
          item1: "Libertad Financiera, claves para 2023",
          item2: (
            <CategoryTag
              className="categoryTag"
              text="Mis Finanzas"
              bg="category-tag-color-3"
            />
          ),
          item3:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
          image:
            "https://static.overlay-tech.com/assets/94264c41-b446-493d-a36f-f6aee83ea34e.png",
        },
        {
          item1: "Comenzando en el mundo de las inversiones",
          item2: (
            <CategoryTag
              className="categoryTag"
              text="Mis finanzas"
              bg="category-tag-color-3"
            />
          ),
          item3:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
          image:
            "https://static.overlay-tech.com/assets/94264c41-b446-493d-a36f-f6aee83ea34e.png",
        },
      ],
    },
    {
      id: "3",
      nombre: "Mi Sector",
      active: false,
      carbBlog: [
        {
          item1: "Beneficis de invertir en el sector en el 2023",
          item2: (
            <CategoryTag
              className="categoryTag"
              text="Mi Sector"
              bg="category-tag-color-4"
            />
          ),
          item3:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
          image:
            "https://static.overlay-tech.com/assets/94264c41-b446-493d-a36f-f6aee83ea34e.png",
        },
        {
          item1: "Nuevos proyectos de inversión",
          item2: (
            <CategoryTag
              className="categoryTag"
              text="Mi Sector"
              bg="category-tag-color-4"
            />
          ),
          item3:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
          image:
            "https://static.overlay-tech.com/assets/94264c41-b446-493d-a36f-f6aee83ea34e.png",
        },
        {
          item1: "Crecimiento del sector para 2023",
          item2: (
            <CategoryTag
              className="categoryTag"
              text="Mi Sector"
              bg="category-tag-color-4"
            />
          ),
          item3:
            "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
          image:
            "https://static.overlay-tech.com/assets/94264c41-b446-493d-a36f-f6aee83ea34e.png",
        },
      ],
    },
  ];

  const showCards = () => {
    if (CODE) {
      
      console.log(
        "cards----------------------xx---------------------->",
        cards
      );
      /* let cards = _buttons.filter((item) => item.id === CODE)[0];
      if (!cards || !cards.carbBlog) {
        return;
      } */
      let cardBlog = [...cards];
      return (
        <div className="row">
          {cardBlog.map((item, i) => {
            return (
              <div
                key={"card-blog-" + i}
                className="col-sm-12 col-md-6 col-lg-6 col-xl-4 col-custom-2 mb-4 container-animated"
              >
                <div className="card-blog-center">
                  <CardBlog
                    category={item.categoria}
                    content1={item.titulo}
                    content2={
                      <CategoryTag
                        className="categoryTag"
                        text={item.categoria.nombre}
                        bg="category-tag-color-3"
                        background={item.categoria.color}
                      />
                    }
                    content3={item.contenido}
                    image={item.image}
                  />
                </div>
              </div>
            );
          })}
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  const handlePageClick = (event) => {

    const newOffset = (event.selected * itemsPerPage) % 6;
    let nextPage = event.selected + 1;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset} ${CODE}`
    );
    
    getArticulosList(CODE != "todo"?CODE:null,nextPage)
    setPage(nextPage)
    setItemOffset(newOffset);
  };

  return (
    <div style={{ width: "100%" }} className="page-academy container-animated">
      <div className="row">
        {!hideBreadcrumbs && (
          <div className="col-xl-12 col-lg-12">
            <BreadcrumbsComponent />
          </div>
        )}
      </div>

      {renderBtn && (
        <div className="row">
          <div className="col-md-3 col-lg-3">
            <MenuBlog buttons={_buttons} setCode={setCode} />
          </div>
          { (
            <div className="col-md-9 col-lg-9">
              {renderCards && showCards()}
              <ReactPaginate
                nextLabel="Siguiente >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={Math.ceil(paginationData.count / itemsPerPage)}
                previousLabel="< Anterior"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
              />
            </div>
          )}
          
        </div>
        
      )}
      
    </div>
  );
}
/* document.getElementsByClassName('textContent')[0].classList.toggle('expanded'); */