import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "./App.css";
import "./fonts/Poppins_1/Poppins-Bold.ttf";
import MainRoutes from "./Routes";
function App() {
  useEffect(() => {
    //window.addEventListener("scroll",detectarScroll)
  }, []);

  return (
    <div className="App">
      <ToastContainer />
      <MainRoutes />
    </div>
  );
}

export default App;
