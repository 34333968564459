import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/Logo/Logo";
import { useForm } from "react-hook-form";
import FormFloatingLabel from "../FormFloatingLabel";
import { newPasswordForm } from "../modelForms/newPasswordForm";
import {
  IconBoxEmail,
  IconBoxEye,
  IconBoxEyeOff,
  IconSecurity,
} from "../Icons/Icons";
import { useState } from "react";
import Boton from "../Boton";
import { toast } from "react-toastify";
import { httpService } from "../../helpers/ApiService";
function SetNewPasswordForm({ checkErrors, isLoad, slug }) {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    getValues,
    setError,
    trigger,
    formState: { errors, touchedFields },
  } = useForm();
  const [passwordType, setPasswordType] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [confirmPasswordInput, setConfirmPasswordInput] = useState("");
  const onBlurInputs = (event) => {
    /* if(event && event.target){
      console.log("onBlurInputs:",event.target.name);
      trigger(event.target.name);
    } */
  };
  const [disableBTN, setDisableBTN] = useState(false);
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const handleConfirmPasswordChange = (evnt) => {
    if (passwordInput === evnt.target.value) {
      setError("password_confirmation", null);
    } else if (confirmPasswordInput) {
      const formError = {
        type: "validate",
        message: "Las contraseñas no coinciden",
      };
      setError("password_confirmation", formError);
    }
    setConfirmPasswordInput(evnt.target.value);
  };
  const re_passwordValidate = (value) => {
    return value === getValues("password");
  };
  const toggleConfirmPassword = () => {
    if (confirmPasswordType === "password") {
      setConfirmPasswordType("text");
      return;
    }
    setConfirmPasswordType("password");
  };
  const handlePasswordChange = (evnt) => {
    if (confirmPasswordInput && confirmPasswordInput === evnt.target.value) {
      setError("password_confirmation", null);
    } else if (confirmPasswordInput) {
      const formError = {
        type: "validate",
        message: "Las contraseñas no coinciden",
      };
      setError("password_confirmation", formError);
    }
    setPasswordInput(evnt.target.value);
  };
  const onSubmit = async (data) => {
    console.log("Data------>", data);

    setDisableBTN(true);
    saveData(data);
  };
  const saveData = (payload) => {
    const id = toast.loading("Solicitando...", {
      closeOnClick: true,
      className: "foo-bar",
    });
    const formData = new FormData();
    formData.append("password", payload.password);
    formData.append("password_confirmation", payload.password_confirmation);
    console.log("New Data", payload.password, payload.password_confirmation);

    const url = `/user/password/reset/done/${slug}/`;
    return httpService(url, formData)
      .post()
      .then(async (res) => {
        console.log("saveData:", res);
        toast.update(id, {
          render: "La contraseña a sido cambiada exitosamente",
          type: "success",
          isLoading: false,
          autoClose: 5000,
          closeOnClick: true,
        });
        navigate("/login", { replace: true });
        return res;
      })
      .catch((err) => {

        console.error("XXXX Response status:------------->", err);
        let errors = [];
        if(err && err.raw && err.raw.data && err.raw.data.errors){
          let result = err.raw.data.errors;
          result.forEach((detail) => {
            console.error("XXXX Response status:", detail);
            errors.push(detail.detail);
          });
          
          toast.update(id, {
            render: errors.join(", "),
            type: "error",
            isLoading: false,
            autoClose: 5000,
            closeOnClick: true,
          });
        }else if(err && err.data && err.data.errors){
          let result = err.data.errors;
          result.forEach((detail) => {
            console.error("XXXX Response status:", detail);
            errors.push(detail.detail);
          });

          toast.update(id, {
            render: errors.join(", "),
            type: "error",
            isLoading: false,
            autoClose: 5000,
            closeOnClick: true,
          });

        }else
        toast.update(id, {
          render: "Ha ocurrido un error, intételo más tarde",
          type: "error",
          isLoading: false,
          autoClose: 5000,
          closeOnClick: true,
        });
        
        setDisableBTN(false);
        return err;
      });
  };
  return (
    <div className="for">
      <div className="logo-img">
        <Logo />
      </div>
      <div className="form-title three text-field-box form-box">
        {isLoad && !checkErrors && (
          <div>
            <h1 className="form-title-sup">
              <span className="color-main">Ingresa la nueva contraseña </span>
              <span className="color-tertiary">y confirmela</span>
            </h1>

            <div className="form-title three text-field-box form-box">
              <div className="form-title-sub">
                Por favor ingresa los siguientes datos
              </div>
            </div>
            <div className="box-button-types">
              <div className="form-title three text-field-box form-box">
                <div className="w-100">
                  <form
                    className="row"
                    onSubmit={handleSubmit((data) => onSubmit(data))}
                  >
                    <div className="col-md-12 mb-4">
                      <div className="form-custom-box">
                        <FormFloatingLabel
                          label={newPasswordForm["password"].label}
                          required={
                            newPasswordForm["password"].validators.required
                              .value
                          }
                        />
                        <div className="custom-input-box">
                          <IconSecurity
                            width="18px"
                            height="16px"
                            colorIcon="#9891bb"
                          />
                          <input
                            type={passwordType}
                            {...register(newPasswordForm["password"].name, {
                              ...newPasswordForm["password"].validators,
                              onChange: (e) => {
                                handlePasswordChange(e);
                              },
                              onBlur: (event) => onBlurInputs(event),
                            })}
                            placeholder={
                              newPasswordForm["password"].placeholder
                            }
                            autoComplete="off"
                          />
                          <div className="icon-action" onClick={togglePassword}>
                            {passwordType === "password" ? (
                              <IconBoxEyeOff
                                width="29px"
                                height="20px"
                                colorIcon="#9891bb"
                              />
                            ) : (
                              <IconBoxEye
                                width="29px"
                                height="20px"
                                colorIcon="#9891bb"
                              />
                            )}
                          </div>
                        </div>
                        {errors.password && (
                          <label className="form-error">
                            {errors.password.message}
                          </label>
                        )}
                      </div>
                    </div>

                    <div className="col-md-12 mb-4">
                      <div className="form-custom-box">
                        <FormFloatingLabel
                          label={newPasswordForm["password_confirmation"].label}
                          required={
                            newPasswordForm["password_confirmation"].validators
                              .required.value
                          }
                        />
                        <div className="custom-input-box">
                          <IconSecurity
                            width="18px"
                            height="16px"
                            colorIcon="#9891bb"
                          />
                          <input
                            name={newPasswordForm["password_confirmation"].name}
                            type={confirmPasswordType}
                            {...register(
                              newPasswordForm["password_confirmation"].name,
                              {
                                ...newPasswordForm["password_confirmation"]
                                  .validators,
                                onChange: (e) => {
                                  handleConfirmPasswordChange(e);
                                },
                                onBlur: (event) => onBlurInputs(event),
                                validate: (value) => re_passwordValidate(value),
                              }
                            )}
                            placeholder={
                              newPasswordForm["password_confirmation"]
                                .placeholder
                            }
                            autoComplete="off"
                          />
                          <div
                            className="icon-action"
                            onClick={toggleConfirmPassword}
                          >
                            {confirmPasswordType === "password" ? (
                              <IconBoxEyeOff
                                width="29px"
                                height="20px"
                                colorIcon="#9891bb"
                              />
                            ) : (
                              <IconBoxEye
                                width="29px"
                                height="20px"
                                colorIcon="#9891bb"
                              />
                            )}
                          </div>
                        </div>

                        {errors.password_confirmation && (
                          <div>
                            {errors.password_confirmation.type ===
                              "required" && (
                              <label className="form-error">
                                Este campo es requerido
                              </label>
                            )}

                            {errors.password_confirmation.type ===
                              "validate" && (
                              <label className="form-error">
                                Las contraseñas no coinciden
                              </label>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      className="col-md-12 mb-2 mt-4"
                      style={{ display: "flex" }}
                    >
                      <Boton
                        disabled={disableBTN}
                        className="btn button-secondary form-box"
                        label="Continuar"
                        redirect="/dashboard"
                        variant="contained"
                      >
                        Continuar
                      </Boton>
                    </div>
                  </form>
                  <div>
                    <div>
                      <div>
                        <h1 className="form-title-sup">
                          <span className="color-main">{checkErrors}</span>
                        </h1>
                        <Link to={"/login"}>Volver al login</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div>
          {checkErrors && isLoad && (
            <div>
              <div>
                <h1 className="form-title-sup">
                  <span className="color-main">{checkErrors}</span>
                </h1>
                <Link to={"/login"}>Volver al login</Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default SetNewPasswordForm;
